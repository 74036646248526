<template>
    <div class="qa-meitukey">
        <div class="qa-meitukey-content">
            <template v-for="(item, index) in i18nPage.content">
                <img :key="index" v-if="item.attr && item.attr.tag === 'img' && item.path" :src="require(`@assets/images${buildForOverseas && item.overseas_path ? item.overseas_path : item.path}`)" alt="" />
                <div :key="index" v-if="typeof item === 'string'" class="p">{{ item }}</div>
                <div :key="index" v-if="item.attr && item.attr.class" :class="['poppins', item.attr.class]">{{ item.text || '' }}</div>
                <br :key="index" v-if="item.attr && item.attr.br" />
                <ul :key="index" v-if="item.attr && item.attr.tag === 'ul'">
                    <li v-for="(li, liIndex) in item.list" :key="liIndex">{{ li }}</li>
                </ul>
                <div :key="index" class="qa" v-if="item.q && item.a">
                    <div class="q">{{ item.q }}</div>
                    <div class="a">{{ item.a }}</div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            buildForOverseas: process.env.VUE_APP_BUILD_FOR === 'evelabinsight',
        };
    },
    computed: {
        i18nPage() {
            return this.$t('meitukeyManuals');
        },
    },
};
</script>

<style lang="scss">
.qa-meitukey {
    @media only screen and (min-width: 769px) {
        &-content {
            width: 740px;
            margin: 0 auto;
            padding: 40px 0;
            font-size: 14px;
            line-height: 22px;
            color: #666;

            img {
                display: block;
                max-width: 100%;
                margin: 20px 0;
            }

            .h1 {
                font-size: 28px;
                font-weight: 700;
                margin: 40px 0;
                color: #000;
            }
            .h2 {
                font-size: 20px;
                font-weight: 600;
                margin: 30px 0;
                color: #000;
            }
            .h3 {
                font-size: 16px;
                font-weight: 600;
                margin: 20px 0;
                color: #000;
            }
            ul {
                padding-left: 20px;
                margin: 10px 0;
                li {
                    &:before {
                        content: "\2022";
                        color: #00C8C8;
                        font-weight: bold;
                        display: inline-block;
                        width: 1em;
                        margin-left: -1em;
                    }
                }
            }
            .qa {
                margin-bottom: 15px;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        &-content {
            padding: 0 0.3rem;
            color: rgba(0, 0, 0, 0.5);
            font-size: 0.14rem;
            line-height: 1.5;

            img {
                display: block;
                max-width: 100%;
                margin: 20px 0;
            }

            .h1 {
                font-size: 0.2rem;
                font-weight: 600;
                margin: 0.3rem 0;
                color: #000;
            }

            .h2 {
                font-size: 0.18rem;
                font-weight: 600;
                margin: 0.2rem 0;
                color: #000;
            }
            .h3 {
                font-size: 0.16rem;
                font-weight: 600;
                margin: 0.2rem 0;
                color: #000;
            }
            .qa {
                margin-bottom: 0.2rem;
            }
            ul {
                padding-left: 20px;
                margin: 10px 0;

                li {
                    &:before {
                        content: "\2022";
                        color: #00C8C8;
                        font-weight: bold;
                        display: inline-block;
                        width: 1em;
                        margin-left: -1em;
                    }
                }
            }
        }
    }
}
</style>
